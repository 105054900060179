import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AcountBalance,
  Apartment,
  BarChart,
  Business,
  DeviceHub,
  DnsWhite,
  Face,
  Gas,
  Group,
  LightBulb,
  Location,
  Logo,
  Paid,
  Payments,
  WhiteArrow,
  Work
} from "@/src/ui/assets/icons";
import Styled from "./sidebar.styled";
import { useTranslation } from "react-i18next";
import paths from "@/src/ui/router/paths";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useSelector } from "react-redux";
import type { RootState, UserPermissions } from "@/src/redux/store";

interface ILink {
  href: string;
  icon: string;
  text: string;
  children?: Array<this>;
  childrenTitle?: string;
  subitems?: Array<this>;
  subitemsTitle?: string;
}

export default function Sidebar() {
  const { t } = useTranslation("sidebar");
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState<ILink>();
  const navbarRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const userPermissions = useSelector((state: RootState) => state.app.userPermissions as unknown as UserPermissions);

  const [userMenu, setUserMenu] = useState<ILink[]>();

  useEffect(() => {
    if (userPermissions) {
      const menu: ILink[] = [];
      if (userPermissions["saving_studies"] && userPermissions["saving_studies"]["list"]) {
        menu.push({
          href: paths.savingStudy.index,
          icon: BarChart,
          text: t("savingStudies.title")
        });
      }
      if (userPermissions["contracts"] && userPermissions["contracts"]["list"]) {
        menu.push({
          href: paths.contract.index,
          icon: Work,
          text: t("contract.title")
        });
      }
      if (userPermissions["clients"] && userPermissions["clients"]["list"]) {
        menu.push({
          href: paths.client.index,
          icon: Group,
          text: t("client.title")
        });
      }
      if (userPermissions["supply_points"] && userPermissions["supply_points"]["list"]) {
        const childs = [];

        childs.push({
          href: paths.supplyPoint.index,
          icon: Location,
          text: t("supplyPoints.title")
        });

        childs.push({
          href: paths.consumptionData.index,
          icon: BarChart,
          text: t("supplyPoints.consumptionData"),
          children: childs
        });

        menu.push({
          href: "",
          icon: Location,
          text: t("supplyPoints.title"),
          children: childs
        });
      }
      if (userPermissions["marketers"] && userPermissions["marketers"]["list"]) {
        const childs = [];
        if (userPermissions["marketers"] && userPermissions["marketers"]["list"]) {
          childs.push({
            href: paths.marketer.index,
            icon: Business,
            text: t("marketers.myMarketers")
          });
        }
        if (userPermissions["rates"] && userPermissions["rates"]["list"]) {
          childs.push({
            href: paths.rate.index + paths.rate.light,
            icon: LightBulb,
            text: t("marketers.rateLight")
          });
          childs.push({
            href: paths.rate.index + paths.rate.gas,
            icon: Gas,
            text: t("marketers.rateGas")
          });
        }

        menu.push({
          href: "",
          icon: Apartment,
          text: t("marketers.title"),
          children: childs
        });
      }

      /*if (userPermissions["roles"] && userPermissions["roles"]["list"]) {
                  menu.push({
                    href: paths.role.index,
                    icon: Key,
                    text: t("roles.title")
                  });
                }*/
      if (
        (userPermissions["rate_types"] && userPermissions["rate_types"]["list"]) ||
        (userPermissions["energy_costs"] && userPermissions["energy_costs"]["list"])
      ) {
        const childs = [];
        if (userPermissions["rate_types"] && userPermissions["rate_types"]["list"]) {
          childs.push({
            href: paths.rateType.index,
            icon: DnsWhite,
            text: t("marketParams.rateType")
          });
        }
        if (userPermissions["rate_types"] && userPermissions["rate_types"]["list"]) {
          childs.push({
            href: paths.energyCost.index,
            icon: Payments,
            text: t("marketParams.energyCosts")
          });
        }

        menu.push({
          href: "",
          icon: AcountBalance,
          text: t("marketParams.title"),
          children: childs
        });
      }
      if (userPermissions["settlements"] && userPermissions["settlements"]["list"]) {
        menu.push({
          href: paths.settlement.index,
          icon: Paid,
          text: t("settlements.title")
        });
      }
      if (userPermissions["users"] && userPermissions["users"]["list"]) {
        menu.push({
          href: paths.profile.index,
          icon: Face,
          text: t("users.title")
        });
      }

      if (userPermissions["channels"] && userPermissions["channels"]["list"]) {
        menu.push({
          href: paths.channel.index,
          icon: DeviceHub,
          text: t("channel.title")
        });
      }
      setUserMenu(menu);
    }
  }, [userPermissions, t]);

  useEffect(() => {
    const onClick = (event: globalThis.MouseEvent) => {
      const target = (event.target as Node) || {};
      const isOutsideSidebar = navbarRef.current && !navbarRef.current.contains(target);
      const targetAsElement = event.target as HTMLElement;
      const isLink = target.parentElement?.hasAttribute("href") || targetAsElement?.hasAttribute("href");

      if (isOutsideSidebar || isLink) {
        setIsOpen(false);
        setIsSubmenuOpen(false);
        setSelectedLink(undefined);
      }
    };

    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, [navbarRef]);

  const onClickNavbar = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  const onClickItemWithChildren = useCallback(
    (link: ILink) => {
      if (!selectedLink || selectedLink.text !== link.text) {
        setSelectedLink(link);
        setIsSubmenuOpen(true);
      } else {
        setSelectedLink(undefined);
      }
    },
    [selectedLink]
  );

  const onClickItemWithoutChildren = useCallback(
    (href: string) => {
      navigate(href);

      if (selectedLink) {
        setSelectedLink(undefined);
      }
    },
    [navigate, selectedLink]
  );

  const onClickLink = useCallback(
    (link: ILink) => {
      if (link.children) {
        onClickItemWithChildren(link);
      } else {
        onClickItemWithoutChildren(link.href);
      }
    },
    [onClickItemWithChildren, onClickItemWithoutChildren]
  );

  if (userPermissions && userMenu) {
    return (
      <Styled.Sidebar isOpen={isOpen} ref={navbarRef}>
        <Styled.SidebarButton isOpen={isOpen} onClick={onClickNavbar}>
          <button>
            <span />
          </button>
          <Logo />
        </Styled.SidebarButton>
        <Styled.Links isOpen={isOpen}>
          {userMenu.map((link) => (
            <Tooltip key={link.text} title={link.text} placement="right">
              <button key={link.text} onClick={() => onClickLink(link)}>
                <link.icon />
                {isOpen && <span>{link.text}</span>}
                {Boolean(link.children?.length) && (
                  <Styled.ArrowIcon>
                    <WhiteArrow />
                  </Styled.ArrowIcon>
                )}
              </button>
            </Tooltip>
          ))}
        </Styled.Links>
        <Styled.Submenu isOpen={Boolean(isSubmenuOpen && selectedLink && selectedLink.children)}>
          <Styled.SubmenuHeader>
            {selectedLink && (
              <div>
                <selectedLink.icon />
                <span>{selectedLink.text}</span>
              </div>
            )}
          </Styled.SubmenuHeader>
          <Styled.Links isOpen={isOpen}>
            {selectedLink?.childrenTitle && <Styled.SubmenuTitle>{selectedLink.childrenTitle}</Styled.SubmenuTitle>}
            {selectedLink?.children?.map(({ href, text, icon: Icon }) => (
              <Link to={href} key={text}>
                <Icon />
                <span>{text}</span>
              </Link>
            ))}
          </Styled.Links>
          <Styled.Links isOpen={isOpen}>
            {selectedLink?.subitemsTitle && <Styled.SubmenuTitle border>{selectedLink.subitemsTitle}</Styled.SubmenuTitle>}
            {selectedLink?.subitems?.map(({ href, text, icon: Icon }) => (
              <Link to={href} key={text}>
                <Icon />
                <span>{text}</span>
              </Link>
            ))}
          </Styled.Links>
        </Styled.Submenu>
      </Styled.Sidebar>
    );
  }
  return null;
}
