import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Close } from "../../assets/icons";
import Styled from "./notification_popup.styled";

interface Props {
  id: number;
  message: string;
  onClose: () => void;
  onMarkAsRead: (id: number) => void;
  style?: React.CSSProperties;
}

export default function NotificationPopup({ id, message, onClose, onMarkAsRead, style }: Props) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onMarkAsRead(id);
      onClose();
    }, 15000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, onMarkAsRead, onClose]);

  const handleMarkAsRead = () => {
    onMarkAsRead(id);
    onClose();
  };

  return (
    <Styled.Container style={style}>
      <Styled.Popup>
        <span>{message}</span>
        <Styled.CloseButton>
          <IconButton onClick={handleMarkAsRead} edge="end">
            <Close />
          </IconButton>
        </Styled.CloseButton>
      </Styled.Popup>
    </Styled.Container>
  );
}
