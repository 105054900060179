import styled from "styled-components";
import { px2rem } from "../../styles/utils";
import { spacing } from "../../styles/spacing";

const Container = styled.div`
  position: fixed;
  top: 20%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1300;
  padding: 16px;
`;

const Popup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #ff8b47;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 300px;
  font-size: 14px;
  font-weight: bold;
  word-wrap: break-word;
  word-break: break-word;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 35px;
  min-width: unset;
  padding: 0;

  button {
    padding: 0;
  }

  svg {
    min-width: ${px2rem(spacing.size4)};
    fill: #fff;
  }
`;

export default { Container, Popup, CloseButton };
