import { useNavigate, useRoutes } from "react-router-dom";
import { lazy, useEffect, useState } from "react";
import { AppErrorBoundary } from "@/src/ui/components/app_error_boundary/app_error_boundary";
import { useMutationRoleProvider } from "@/src/ui/pages/role/provider/mutation_role.provider";

const ConsumptionDataPage = lazy(() => import("@/src/ui/pages/consumption_data/views/consumption_data_page/consumption_data_page"));
export default function ConsumptionDataPages() {
  const navigate = useNavigate();
  const [routeList, setRouteList] = useState<object[]>([]);

  useEffect(() => {
    setRouteList([
      {
        index: true,
        element: <ConsumptionDataPage />
      }
    ]);
  }, [navigate]);

  const page = useRoutes(routeList);
  return (
    <AppErrorBoundary key="role-error">
      <useMutationRoleProvider.State>{page}</useMutationRoleProvider.State>
    </AppErrorBoundary>
  );
}
