import type { MoneyModel } from "@/src/core/app/domain/models/money";
import type { BasicRateTypeModel } from "@/src/core/rate_type/domain/models/basic_rate_type_model";
import type { SelectedRateModel } from "@/src/core/saving_study/domain/models/selected_rate/selected_rate_model";
import { stringToFloat } from "@/src/common/utils/index";

export function number_format(value?: number, money = true, decimals = 0) {
  if (value === undefined || value === null) {
    return "";
  }
  let options;
  if (money) {
    options = {
      useGrouping: true,
      maximumFractionDigits: decimals,
      style: "currency" as Intl.NumberFormatOptionsStyle,
      currency: "EUR"
    };
  } else {
    options = {
      useGrouping: true,
      maximumFractionDigits: decimals
    };
  }

  return new Intl.NumberFormat("es-ES", options).format(value);
}

export function percent_format(value?: number, decimals = 0) {
  if (value === undefined) {
    return "";
  }
  const options = {
    style: "percent" as Intl.NumberFormatOptionsStyle,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  };

  return new Intl.NumberFormat("es-ES", options).format(value / 100);
}

export function money_format(
  value:
    | string
    | number
    | MoneyModel
    | SelectedRateModel
    | BasicRateTypeModel
    | (() => {
        createdAt: string;
      })
    | undefined,
  decimals = 0
) {
  const val = value ? (value as MoneyModel)?.amount : "";

  if (val !== "") {
    return number_format(stringToFloat(String(val)), true, decimals);
  }
  return val;
}
